<template>
  <a-layout class='index animated fadeIn'>
    <div class="s_bf">
      <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item ref="level" label="资质名称" name="level">
          <div class="s_flex_ali">
            <p class="s_c333 mr15">中国商标权证书</p>
            <div class="s_flex_ali">
              <WarningOutlined v-if='status !== 1' class="s_cd41" />
              <CheckOutlined v-else class="s_c376" />
              <p>{{status_text}}</p>
            </div>
          </div>
        </a-form-item>
        <a-form-item ref="brand_name" label="品牌名称" name="brand_name">
          <a-input v-model:value="form.brand_name" placeholder='请输入企业名称' readonly/>
        </a-form-item>
        <a-form-item ref="trademark_no" label="商标注册号/申请号" name="trademark_no">
          <a-input v-model:value="form.trademark_no" placeholder='请输入商标注册号/申请号' readonly />
        </a-form-item>
        <a-form-item ref="trademark_images" label="中国商标权证书" name="trademark_images">
          <a-image-preview-group>
            <a-image v-for='item in form.trademark_images' :key='item' :width='180' :height='180' src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"/>
          </a-image-preview-group>
        </a-form-item>
        <a-form-item ref="extend_images" label="补充材料" name="extend_images">
          <a-image-preview-group>
            <a-image v-for='item in form.extend_images' :key='item' :width='180' :height='180' src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"/>
          </a-image-preview-group>
        </a-form-item>
      </a-form>
    </div>
  </a-layout>
</template>
<script>
  import { WarningOutlined, CheckOutlined } from "@ant-design/icons-vue";
  import { ref, onMounted, reactive, toRefs, inject,watchEffect } from 'vue';
  import { message } from 'ant-design-vue';
  // 初始化默认筛选项数值
  let defForm = {
    brand_name:'',
    trademark_no:'',
    trademark_images:'',
    extend_images:''
  }
  export default {
    name:'licence',
    components: {WarningOutlined, CheckOutlined },
    props: {
      formData: {
        type: Object
      },
    },
    setup(props,{emit}) {
      //pass
      const form = ref({ ...defForm });
      const state = reactive({
        status:0,
        status_text:'未认证',
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
      });
      const ruleForm = ref()
      const _lodash = inject("_lodash");
      const onSubmit = () => {
        ruleForm.value.validate().then(() => {
          emit('onsubmit',form.value)
        }).catch(error => {
            console.log('error', error);
        });
      }
      onMounted(() => {
      });
      
      watchEffect(() => {
        var formData = props.formData || {};
        console.log(formData)
        if (!_lodash.isEmpty(formData.content)) {
          form.value = formData.content;
          state.status = formData.status
          state.status_text = formData.status_text
        } else {
          form.value = defForm;
          state.status = formData.status
          state.status_text = formData.status_text
        }
      });
      return {
        form,
        ...toRefs(state),
        ruleForm,
        onSubmit
      }
    }
  }
</script>
<style>
</style>